import * as React from 'react'
import { enhancedFetch, Spinner } from 'journey-ui'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

/* Do the redirecting clientside and not in getServersideProps, was having problems in certain android webviews where we weren't properly getting hydrated pages. */
const HomePage: React.FC = () => {
  const [initiated, setInitiated] = useState(false)

  const router = useRouter()

  useEffect(() => {
    if (initiated) {
      return
    }

    setInitiated(true)

    async function init() {
      const { url } = await enhancedFetch<{ url: string }>('/api/user/pos-home-url')

      return router.replace(url)
    }

    init().catch(() => {
      router.replace('/login')
    })
  }, [initiated, router])

  return (
    <div className="py-12">
      <Spinner />
    </div>
  )
}

export default HomePage
